* {
  font-family: 'Poppins', sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: Poppins, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'corporatecondensedbold';
  src: url(/static/media/corporatecondensedbold.431ee677.woff2) format('woff2'),
    url(/static/media/corporatecondensedbold.8fac2ae6.woff) format('woff'),
    url(/static/media/corporatecondensedbold.ebef3000.ttf) format('truetype');
}
@font-face {
  font-family: 'corporatemonocondensed';
  src: url(/static/media/corporatemonocondensed.99b5597d.woff2) format('woff2'),
    url(/static/media/corporatemonocondensed.76d54f01.woff) format('woff'),
    url(/static/media/corporatemonocondensed.8367604b.ttf) format('truetype');
}
@font-face {
  font-family: 'IDAutomationHC39MCode39Barcode';
  src: url(/static/media/IDAutomationHC39MCode39Barcode.09d1b537.woff2)
      format('woff2'),
    url(/static/media/IDAutomationHC39MCode39Barcode.5863e1ab.woff) format('woff'),
    url(/static/media/IDAutomationHC39MCode39Barcode.69edb6c0.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  src: url(/static/media/Poppins-Regular.8b6af8e5.ttf) format('truetype');
}

:root {
  --var-color-primary-black: #000000;
  --var-color-primary-grey: #efefee;
  --var-color-primary-yellow: #efa817;
  --var-color-primary-white: #ffffff;
  --var-color-primary-blue-text: #455a64;
  --var-color-primary-red: #b5121b;
}
iframe {
  display: none;
}

.flex_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh; }

.page_background {
  background-color: var(--var-color-primary-grey); }

.icon {
  height: 12em; }

.text_color {
  color: var(--var-color-primary-black) !important; }

.home_button {
  color: var(--var-color-primary-white) !important;
  background: var(--var-color-primary-yellow) !important; }

.home_button_text {
  color: inherit;
  text-decoration: none; }

.page-headerBar__toolbar {
  display: flex;
  justify-content: space-between; }



.page-companyChoose {
  background-color: var(--var-color-primary-grey);
  height: 100vh; }

.page-companyChoose__header {
  top: 0;
  margin-top: 0;
  position: -webkit-sticky;
  position: sticky; }

.page-companyChoose__container {
  margin-top: 50px;
  text-align: center;
  font-size: 1em; }

.customDialogContainer {
  z-index: 10000;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: -webkit-fill-available; }

.paper {
  width: 20vw;
  position: absolute;
  top: 10%;
  left: 29%;
  padding: 1em; }

.countryContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.countryImage {
  width: 30%;
  border-radius: 50%; }

.countryName {
  font-weight: 600; }

.countryLabel {
  margin: 1em 0 0 0;
  color: #EFA817; }

.country {
  padding: 0.5em;
  padding-left: 0; }

.page-dashboard {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.page-dashboard__grid-item {
  background-color: #fff;
  box-shadow: 1px 1px 10px 3px #ccc; }

.page-dashboard__country-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px; }

.page-dashboard__card-header {
  display: grid;
  grid-template-columns: 86% 14%;
  font-size: 13px;
  margin: 0 10px; }

.page-dashboard__card-title {
  font-size: 11px;
  display: grid;
  grid-template-columns: 55% 23% 22%;
  color: #6f6f6f;
  margin: 4px 10px 10px 10px; }

.page-dashboard__card-list {
  color: #434343; }

.page-dashboard__card-list-item {
  display: grid;
  grid-template-columns: 10% 76% 14%;
  align-items: center;
  font-size: 11px;
  margin: 0 10px 5px 10px; }

.page-dashboard__card-footer {
  display: grid;
  grid-template-columns: 100%; }

.page-dashboard__card-footer button {
  background-color: #f4f5f6;
  border: 0px;
  height: 30px;
  color: #626262;
  font-weight: bold; }

.page-dashboard__middle-button {
  background-color: #e9e9e9 !important; }

.page-dashboard__flag-icon {
  height: 50px;
  width: 50px;
  border-radius: 8px; }

.icon_button {
  padding: 0.1em !important;
  margin: 0 0 0 1.6em !important; }

.page-dashboard__grid-container {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-gap: 15px; }

.page-dashboard__grid-item {
  background-color: #fff;
  box-shadow: 1px 1px 10px 3px #ccc; }

.page-dashboard {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.page-login {
  background-color: #efefee; }

.page-login__container {
  max-width: 600px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.logo {
  display: block;
  margin: 0 auto;
  max-width: 7.7em !important;
  margin: 1.3em 0 !important; }

.login-card {
  width: 100%; }

.login-title {
  font-weight: bold; }

.login-input {
  margin: 0.5em !important;
  width: 100%; }

.login-forgot-password {
  color: #f1b231;
  display: inline-block;
  float: right;
  text-align: right;
  margin-right: 20px 50 5px 50; }

.logo-sundevs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.4em 0; }

.sundevs {
  width: 3em; }

.page-reset {
  background-color: #efefee; }

.page-reset__container {
  max-width: 600px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 140px; }

.reset-card {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }

.reset-title {
  font-weight: bold; }

.reset-subtitle {
  font-weight: bold; }

.reset-footer {
  padding: 25;
  font-weight: bold; }

.reset-input {
  width: 100%; }

.update-card {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }

.update-title {
  font-weight: bold; }

.update-subtitle {
  font-weight: bold; }

.update-footer {
  padding: 25;
  font-weight: bold; }

.update-card {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }

.update-title {
  font-weight: bold; }

.update-subtitle {
  font-weight: bold; }

.update-footer {
  padding: 25;
  font-weight: bold; }

.page-update {
  background-color: #efefee; }

.page-update__container {
  max-width: 600px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 140px; }

.update-card {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }

.update-title {
  font-weight: bold; }

.update-subtitle {
  font-weight: bold; }

.update-footer {
  padding: 25;
  font-weight: bold; }

.update-input {
  width: 100%; }

.page-update {
  background-color: #efefee; }

.page-update__container {
  max-width: 600px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 140px; }

.update-card {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }

.update-title {
  font-weight: bold; }

.update-subtitle {
  font-weight: bold; }

.update-footer {
  padding: 25;
  font-weight: bold; }

.update-input {
  width: 100%; }

.page-new-user {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.new_user__title-form {
  border-bottom: 1px solid #EFF1F1;
  padding: 10px 10px 0px 10px; }

.new_user__title-list {
  padding: 10px 10px 0px 10px; }

.new_user__content-form {
  padding: 16px;
  padding-bottom: 50px !important; }

.header__toolbar {
  flex-grow: 1; }

.New-user-save-button {
  color: #FFFFFF;
  float: right;
  margin-top: -50px !important;
  margin-right: 8px !important;
  background-color: #EFA817 !important; }

.New-user-back-button {
  color: #EFA817 !important;
  float: right;
  margin-top: -50px !important;
  margin-right: 130px !important;
  background-color: #EFA817; }

.page-format {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.page-language__container {
  background-color: #fff; }

.page-language__form-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center; }

.description_length_checker {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  margin: 5px;
  color: gray; }

.container-grid {
  display: grid; }

.format-main-container {
  grid-template-columns: 320px auto;
  grid-column-gap: 40px; }

.main-container-next-tab {
  grid-template-columns: 35% 65%; }

.row-container {
  grid-auto-flow: column; }

.component-container {
  grid-auto-flow: column;
  grid-template-columns: 99% 1%;
  padding: 1px 0; }

.component-rows-container {
  grid-auto-flow: row; }

.column-container {
  grid-auto-flow: column;
  grid-template-columns: 95% 5%; }

.column-item {
  margin: 0 1px;
  display: flex; }

.main-container-item {
  margin: 0 15px; }

.kiosk-status__card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 2px 3px 5px 1px #ccc; }

.kiosk-status__card-icon img {
  border-radius: 50%; }

.kiosk-status__card-title span {
  display: block; }

.kiosk-status__card-number {
  font-size: 40px;
  font-weight: bold;
  color: #3f3f3f; }

.page-status {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.kiosks-status {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-column-gap: 10px;
  margin: 20px 10px 20px 20px; }

.text-total {
  color: #7c9efft; }

.text-service {
  color: #74e077; }

.text-without-service {
  color: #ff6e6a; }

.text-warning {
  color: #f69c21; }

.list-kiosks {
  background-color: #ffffff;
  margin: 20px; }

.form-input-subtitle {
  display: block; }

.form-group {
  margin-bottom: 15px; }

.form-button-container {
  display: flex;
  justify-content: flex-end; }

.form-input-subtitle {
  display: block; }

.form-group {
  margin-bottom: 15px; }

.form-button-container {
  display: flex;
  justify-content: flex-end; }

.formats__table-edit {
  padding-left: 20px; }

.page-language__container {
  background-color: #fff;
  padding-bottom: 10px; }

.page-language__find-field {
  width: 97.5%;
  font-size: 14px;
  background-color: #F7F7F7;
  margin: 10px 0 0 0;
  border: 0;
  color: '#fff';
  padding: 10px; }

.page-language__form-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center; }

.panel_title {
  padding: 1em; }

.expansion {
  width: 100%;
  margin: 0.3em 0; }

.expansion_container {
  flex-direction: column; }

.container {
  padding: 1em;
  width: 50em;
  align-self: center; }

.text_container {
  display: flex;
  justify-content: space-around;
  margin: 1em 0; }

.radio_container {
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row !important;
  height: 2em;
  padding: 1em; }

.radio {
  color: var(--var-color-primary-yellow) !important; }

.title_container {
  display: flex;
  flex-direction: column;
  align-items: center; }

.call_to_action {
  display: flex;
  justify-content: space-between;
  margin: 2em 0; }

.url_youtube {
  margin: 2em 0; }

.expansion_panels {
  margin: 0.5em 0; }

.save_button {
  color: white !important;
  display: flex !important;
  padding: 0.5em 1.3em !important;
  background-color: #EFA817 !important;
  margin: 0 86.7% !important; }

.banner_button {
  display: flex;
  justify-content: flex-end; }

.add_banner_button {
  background-color: white !important;
  box-shadow: none !important;
  padding: 0.3em 1em !important;
  border: 1px solid !important; }

.video_thumbnail {
  width: 3.4em; }

.image_banner {
  margin: 0 0 0 0.7em; }

.actions_container {
  display: flex; }

.button-container {
  display: flex;
  justify-content: flex-end; }

.expansion_body {
  display: flex;
  flex-direction: column; }

.banner_title {
  padding: 1em; }

.dialogContent {
  padding: 1em !important;
  overflow-y: hidden !important; }

.locationsContainer {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 11em;
  border-top: 1px solid;
  margin: 0.2em 0; }

.lengthContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.inputsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.iputs {
  width: 48%; }

.createArea {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.container-grid {
  display: grid; }

.row-container {
  grid-auto-flow: column; }

.component-container {
  grid-auto-flow: column;
  grid-template-columns: 100%;
  padding: 1px 0; }

.component-rows-container {
  grid-auto-flow: row; }

.column-container {
  grid-auto-flow: column;
  grid-template-columns: 100%; }

.column-item {
  margin: 0 1px;
  display: flex; }

.button-container {
  display: flex;
  justify-content: flex-end; }

.container-modal {
  width: 40%;
  margin-left: 29vw;
  margin-top: 25vh;
  background: #fff;
  padding: 1rem; }

.container-buttons {
  text-align: right; }

.page-format {
  background-color: #fff;
  min-height: 0vh;
  height: 100%; }

.page-language__container {
  background-color: #fff; }

.page-language__form-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center; }

.description_length_checker {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  margin: 5px;
  color: gray; }

.container-grid {
  display: grid; }

.main-container {
  grid-template-columns: 25% 35%;
  grid-column-gap: 40px; }

.main-container-next-tab {
  grid-template-columns: 35% 65%; }

.row-container {
  grid-auto-flow: column; }

.component-container {
  grid-auto-flow: column;
  grid-template-columns: 99% 1%;
  padding: 1px 0; }

.component-rows-container {
  grid-auto-flow: row; }

.column-container {
  grid-auto-flow: column;
  grid-template-columns: 95% 5%; }

.column-item {
  margin: 0 1px;
  display: flex; }

.main-container-item {
  margin: 0 0;
  box-shadow: none; }

.page-settings {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.page-language__container {
  background-color: #fff; }

.page-language__form-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center; }

.container {
  padding: 1em; }

.field {
  width: 98%; }

.settings_container {
  display: grid;
  grid-template-columns: 50% 50%; }

.save_country_settings {
  left: 22.1em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  top: 2em;
  color: var(--var-color-primary-yellow) !important; }

.page-dashboard {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.rows_container {
  display: flex;
  flex-direction: row;
  width: 100%; }

.row {
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.rows_spacer {
  width: 100%; }

.page-status {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.kiosks-status {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-column-gap: 10px;
  margin: 20px 10px 20px 20px; }

.text-total {
  color: #7c9efft; }

.text-service {
  color: #74e077; }

.text-without-service {
  color: #ff6e6a; }

.text-warning {
  color: #f69c21; }

.list-kiosks {
  background-color: #ffffff;
  margin: 20px; }

.actions-container {
  display: flex !important;
  flex-direction: row; }

.page-users {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.users__button {
  margin-top: 78px !important;
  margin-right: 20px !important;
  color: #FFFFFF !important;
  background-color: #EFA817 !important;
  float: right; }

.users__button:hover {
  background-color: #FFB319 !important; }

.users__modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.users__modal__paper {
  position: absolute;
  width: 400px;
  backgroundColor: #FFFFFF;
  boxShadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  padding: 32px;
  outline: none; }

.users__table-delete {
  padding-right: 10px;
  cursor: pointer; }

.users__table-edit {
  cursor: pointer; }

.page-kiosk {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.charts-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.page_header_container {
  padding: 15px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.page_header_container__left_content {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row; }

.config__button {
  color: #FFFFFF !important;
  background-color: #EFA817 !important; }

.page-transactions {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.find-field {
  width: 98%;
  font-size: 14px;
  background-color: #efa817;
  margin: 10px 0 0 0;
  border: 0;
  color: #fff;
  padding: 10px; }

.add_location {
  background-color: #efa817 !important;
  color: white !important;
  padding: 0.5em 1em !important;
  margin: 0.2em 0 !important;
  border-radius: 4px !important; }

.dialog_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px !important; }

.button_close {
  top: 8px;
  color: #9e9e9e;
  right: 8px;
  position: absolute !important; }

.form_container {
  display: flex;
  justify-content: space-between;
  margin: 1em 0; }

TextField {
  margin: 0.3em !important; }

.page-status {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.page_container {
  padding-top: 20px;
  height: 70px; }

.switch_city_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: #ffffff; }

.kiosks-status {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-column-gap: 10px;
  margin: 20px 10px 20px 20px; }

.text-total {
  color: #7c9efft; }

.text-service {
  color: #74e077; }

.text-without-service {
  color: #ff6e6a; }

.text-warning {
  color: #f69c21; }

.list-kiosks {
  background-color: #ffffff;
  margin: 20px; }

.page-status {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.page_container {
  padding-top: 20px;
  height: 70px; }

.switch_city_container {
  padding: 10px;
  background-color: #ffffff; }

.kiosks-status {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-column-gap: 10px;
  margin: 20px 10px 20px 20px; }

.text-total {
  color: #7c9efft; }

.text-service {
  color: #74e077; }

.text-without-service {
  color: #ff6e6a; }

.text-warning {
  color: #f69c21; }

.list-kiosks {
  background-color: #ffffff;
  margin: 20px; }

.page-transactions {
  background-color: #fff;
  min-height: 100vh;
  height: 100%; }

.find-field {
  width: 98%;
  font-size: 14px;
  background-color: #efa817;
  margin: 10px 0 0 0;
  border: 0;
  color: #fff;
  padding: 10px; }

.itemDetailContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.detailRow {
  padding: 0 !important; }

.page-kiosk {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.kiosk-info {
  margin: 20px; }

.page_header_container {
  padding: 15px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.page_header_container__left_content {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row; }

.config__button {
  color: #FFFFFF !important;
  background-color: #EFA817 !important; }

.delete__button {
  margin: 10px !important;
  color: #FFFFFF !important;
  background-color: #3f3f3f !important; }

.kiosk_detail_container {
  display: flex;
  flex-direction: row; }

.kiosk_detail_left_side {
  width: 70%; }

.kiosk_detail_row {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.form-input-subtitle {
  display: block; }

.form-group {
  margin-bottom: 15px; }

.form-button-container {
  display: flex;
  justify-content: flex-end; }

.page-users {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.users__button {
  margin-top: 78px !important;
  margin-right: 20px !important;
  color: #FFFFFF !important;
  background-color: #EFA817 !important;
  float: right; }

.users__button:hover {
  background-color: #FFB319 !important; }

.users__modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.users__modal__paper {
  position: absolute;
  width: 400px;
  backgroundColor: #FFFFFF;
  boxShadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  padding: 32px;
  outline: none; }

.users__table-delete {
  padding-right: 10px;
  cursor: pointer; }

.users__table-edit {
  cursor: pointer; }

.page-new-user {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%; }

.new_user__title-form {
  border-bottom: 1px solid #EFF1F1;
  padding: 10px 10px 0px 10px; }

.new_user__title-list {
  padding: 10px 10px 0px 10px; }

.new_user__content-form {
  padding: 16px; }

.header__toolbar {
  flex-grow: 1; }

.MuiSwitch-colorPrimary {
  color: #EFA91B !important; }

.role__button {
  margin: 1rem 0.5rem !important;
  color: #FFFFFF !important;
  background-color: #EFA817 !important; }

.role__button:hover {
  background-color: #FFB319 !important; }

.role__button:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important; }

.role__button_cancel {
  margin: 1rem 0.5rem !important;
  color: #EFA817 !important; }

.role__button_cancel:hover {
  color: #FFB319 !important;
  border: 1px solid #FFB319;
  background: #fff !important; }

.role__container_buttons {
  text-align: right; }

.flex_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh; }

.page_background {
  background-color: var(--var-color-primary-grey); }

.icon {
  height: 12em; }

.text_color {
  color: var(--var-color-primary-black) !important; }

.home_button {
  color: var(--var-color-primary-white) !important;
  background: var(--var-color-primary-yellow) !important; }

.home_button_text {
  color: inherit;
  text-decoration: none; }

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

